// import { FilterOptionsObj, GAT, GV } from "../DataManagement/DataManagement";
// import { SortByHeat, SortByResiliency } from "./sortFunctions";
import { GAT } from '../GData/GData';
import './displayAttack.css';



import { HeatElement, TacticElement, TechniqueElement, checkNameLength } from "./displayShared";
import { JSXElementConstructor, ReactElement } from "react";
import { SiMediafire } from "react-icons/si";

export function generateDisplayAttackSurface(params:any){
    const isSelected = (uniqueId:string) => {
      if(!params?.selectedTechs) return '';
      if(params.selectedTechs.has(`${uniqueId}#a`)) return 'tech-slx';
      if(params.selectedTechs.has(`${uniqueId}#d`)) return 'tech-nslx';
    }
    // const params = props.params;
    

    let dataOwner = params?.titleLayer || [];
    let scoredValues:any = {};

    // let COAs = GV[params.activeProject]?.COA || {};
    // let isCOA = false;
    // if(Object.keys(COAs).includes(dataOwner.at(-1))){
    //   dataOwner = dataOwner.at(-1);
    //   scoredValues = GV[params.activeProject].COA[dataOwner].scored;
    //   isCOA = true;
    // }else{
    //   dataOwner = params.activeProject;
    //   scoredValues = GV[params.activeProject].scored;
    // }

    let sortingMethod = {};
    // switch(params.filterSelected){
    //   case 'filter-name':
        sortingMethod = GAT[params.AV]['framework']['tactics'];
    //     break;
    //   case 'filter-heat':
    //     sortingMethod = SortByHeat(params.AV, params.activeProject);
    //     break;
    //   case 'filter-weight':
    //     sortingMethod = SortByResiliency(params.AV, scoredValues, 'weighted');
    //     break;
    //   case 'filter-thermal':
    //     sortingMethod = SortByResiliency(params.AV, scoredValues, 'thermal');
    //     break;
    //   default:
    //     sortingMethod = GAT[params.AV]['framework']['tactics'];
    // }

    let Elements : Array <HTMLElement | any> = [];

    const techPadding = 20;

    const trect = {
      w:250,
      h:50
    }

    const Hrect = {
      w: 20,
      h: 50
    }

    let heatPadding = 0;
    let WeightIcon:any = null;
    if(params?.showWeights?.show === true){
      heatPadding = Hrect.w;
      // WeightIcon = FilterOptionsObj.filter((obj:any) => obj.label === params.showWeights.type)[0].icon;
    }  

    const subTechniqueModifier = {
      'decrease' : 0.80
    }
    let maxHeight = 0;
    let maxWidth = 0;
    Object.entries(sortingMethod).map((tacticObj:any, i_1:number)=>{
      const [tactic, tacticEntry] = tacticObj;
      const xrect = (trect.w + heatPadding + techPadding)*i_1;
      const xtext = xrect + trect.w/2;
      Elements.push(
        TacticElement({
          tactic: tactic,
          xrect: xrect,
          yrect: 0,
          xtext: xtext,
          ytext: trect.h/2,
          width: trect.w,
          height: trect.h,
          options: {
          }
        })
      )

      if(WeightIcon){
        const xicon = xrect + trect.w + 3;
        let _icon = {...WeightIcon, props: {'attr' : { x: xicon, y: 0, viewBox: '0 0 24 24'}, size: '3em'}};
        Elements.push(
          _icon
        )
      }
            

      const colHeight = Object.entries(tacticEntry.techniques).length
      if(colHeight > maxHeight) maxHeight = colHeight;

      let i = 0;
      Object.entries(tacticEntry.techniques).map((tcodeObj:any)=>{ // Change sorting method here!
        const [tcode, tcodeEntry] = tcodeObj;
        const uniqueId = String(tcode + tactic);
        
        const isSub = tcode.includes('.');
        let techW = trect.w;
        let techXAdd = 0;
        let threatClass = 'threat'
        
        if(params.showGaps){
          if(scoredValues?.[uniqueId]?.[params.pdrButton]) return;
        }
        if(isSub){
          techW = trect.w * subTechniqueModifier.decrease;
          techXAdd = trect.w * ((1-subTechniqueModifier.decrease));
          threatClass = 'subthreat'
        }
        if(isSub && !params.showSubsValue){
          return;
        }
        // if(GV[params.activeProject]?.inscope){
        //   if(params.showInscopeValue && !GV[params.activeProject].inscope.has(uniqueId)){return;}
        // }
        
        const rectX = (trect.w + heatPadding + techPadding)*i_1 + techXAdd;
        const rectY = trect.h*(i + 1);
        const heatRectX = (trect.w + heatPadding + techPadding)*i_1 + trect.w;
        
        let textX = (trect.w + heatPadding + techPadding)*i_1;
        let textY = trect.h*(i + 1) + trect.h/2;
        let fillColor = '#fff';
        // if(params?.overlay && params.overlay.includes(uniqueId)) fillColor='#4286f4'
        if(params?.colorOverlay && uniqueId in params.colorOverlay) fillColor=params.colorOverlay[uniqueId]

        let [techName, textClass] = checkNameLength(tcodeEntry.name, isSub);
        Elements.push(
          TechniqueElement({
            tcode : tcode,
            tactic : tactic,
            xrect : rectX,
            yrect : rectY,
            xtext : textX + techW/2 + techXAdd,
            ytext : textY,
            width : techW,
            selected : isSelected(uniqueId),
            text : techName,
            textClass : textClass,
            height : trect.h,
            fill : fillColor,
            options : {
            }
          })
        )
        if(params?.showWeights?.show === true) 
        {
          let score:any = 0;
          let fill = '#f2f2f2';
          
          // _icon.props = {x: heatRectX + 5, y: rectY + 5, width: 45, height: 45};
          // switch(params.showWeights.type){
          //   case 'Heat':
              score = GAT?.['13']?.['counter']?.['techniques']?.[uniqueId]?.count || null;
              // fill = GV[params.activeProject].heat.overlay[uniqueId];
              fill = GAT?.['13']?.['scores']?.[uniqueId] || 'white';
          //     break;
          //   case 'Thermal':
          //     score = scoredValues?.[uniqueId]?.thermal || 0;
          //     score = Number(score.toFixed(2));
          //     break;
          //   case 'Weight':
          //     score = scoredValues?.[uniqueId]?.weighted || 0;
          //     score = (score * 100).toFixed(1) + '%';
          //     break;
          // }
          
          Elements.push(
            HeatElement({
              uniqueId : uniqueId,
              width: Hrect.w,
              height: trect.h,
              xrect : heatRectX,
              yrect : rectY,
              xtext : heatRectX + Hrect.w/2,
              ytext : textY,
              fill : fill,
              score : score,
            })
          );
          
        }
        ++i;
      })
    })
    maxHeight = maxHeight * trect.h;
    maxWidth = (Object.entries(GAT[params.AV]['framework']['tactics']).length) * (trect.w + techPadding);
    return [Elements, maxWidth, maxHeight];
  }