import { Dialog } from "primereact/dialog";
import { GAT, GP } from "../GData/GData";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { uniqueIdParse } from "../GData/GFunctions";
import { useRef } from "react";
import { Button } from "primereact/button";


export default function TopTenDialog(props:any){
    const dt = useRef<any>(null);
    if(!props.isActive) return <></>;


    const exportCSV = (selectionOnly:any) => {
        dt.current.exportCSV({ selectionOnly });
    };

    const header = (
        <div className="flex d-flex flex-row align-items-center justify-content-between">
            <div>Top 10 ATT&CK Techniques to Mitigate</div>
            <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(false)} data-pr-tooltip="CSV" style={{marginRight: '0.5rem', background: 'var(--nav-background)'}}/>
        </div>
    );
    const scores = GP?.now?.scores || {};
    const scoreArr = Object.keys(scores);

    const counterSort = Object.keys(GAT['13'].counter.techniques).map((t:any) => {
        return [GAT['13'].counter.techniques[t].count, t];
    });

    counterSort.sort((a, b) => a[0] - b[0]);
    counterSort.reverse();

    const topTen = () => {
        let topTen:any = [];
        let topTcodes:Array<string> = [];
        counterSort.forEach((t:any) => {
            if(topTen.length >= 10) return;
            const [score, uniqueId] = t;
            if(scoreArr.includes(uniqueId)) return;
            const [tcode, tactic] = uniqueIdParse(uniqueId);
            if(topTcodes.includes(tcode)) return;
            topTcodes.push(tcode);
            topTen.push({
                score: score,
                uniqueId: uniqueId,
                tactic: tactic,
                tcode: tcode,
                name: GAT['13'].framework.tactics?.[tactic]?.techniques?.[tcode]?.name || 'Unknown',
            });
        });
        return topTen;
    };

    const tableData = topTen();

    return(
        <Dialog header={header} 
                visible={props.isActive} 
                style={{ width: 'fit-content' }} 
                onHide={() => props.setActive(false)} 
                className=''
                dismissableMask={true}>
                <DataTable value={tableData}
                        ref={dt}
                        sortField="capability"
                        size='small'
                        width='100%'>
                    <Column field="name" header="Technique Name" ></Column>
                    <Column field="tcode" header="Id" ></Column>
                    <Column field="score" header="Threat Counter" ></Column>
                </DataTable>
        </Dialog>
    )
}