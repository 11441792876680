
import { Card } from 'primereact/card';
import './ProductStyle.css';
import { BiShield } from 'react-icons/bi';
import { GoAlert } from 'react-icons/go';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { GP } from '../GData/GData';

export default function ProductCard(props:any){
    let bgImage = props?.url?.image || null;

    return(
        <div className={`pt-card ${props.active}`} onClick={props.onClick} data-prod={props.value.product}>
            {bgImage && <img src={`/logos${bgImage}`} className='pt-logo'></img>}
            {!bgImage && <h3 style={{padding: '1rem'}}>{props.value.product}</h3>}
            
            <div className='pt-stats'>
                <div style={{color: 'var(--color-csf-protect)'}}>
                    <BiShield />{props.value['protect-count']}
                </div>
                <div style={{color: 'var(--color-csf-detect)'}}>
                    <GoAlert />{props.value['detect-count']}
                </div>
            </div>
            <AiOutlineInfoCircle className='pt-info-icon'
                    onClick={() => window.open(GP?.url?.[props.value.product]?.url || '', '_blank')}/>
        </div>
    )
}