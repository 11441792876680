import React, {useEffect, useRef, useState} from 'react';
import { GAT, GP, engImageURL } from "../GData/GData";
import { Dialog } from 'primereact/dialog';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { PiImageLight } from 'react-icons/pi';
import { BsInfoCircleFill } from 'react-icons/bs';
import { Galleria } from 'primereact/galleria';
import $ from 'jquery';

import { TransformWrapper, TransformComponent, ReactZoomPanPinchRef } from "react-zoom-pan-pinch";

import './PopupEng.css'
import handleClick from '../ThreatApp/ThreatHandleClick';

function sumArrayUpToIndex(arr:any, endIndex:number) {
    // if (endIndex < 0 || endIndex >= arr.length) {
    //   throw new Error('Invalid endIndex');
    // }
  
    let sum = 0;
    for (let i = 0; i <= endIndex; i++) {
      sum += arr[i];
    }
    return sum;
  }

const ItemTemplate = (item:any) => {
    // const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null);
    // const zoomToImage = () => {
    //     if (transformComponentRef.current) {
    //       const { zoomToElement } = transformComponentRef.current;
    //       zoomToElement("imgExample");
    //     }
    //   };

    // useEffect(() => {
    //     zoomToImage();
    // },[]);

    if(!item?.itemImageSrc) return(
        <div style={{padding: '1rem'}}>
            No image to display.
        </div>
    );

    return(
        <>
            <TransformWrapper initialScale={1} limitToBounds={false}> 
                <TransformComponent>
                    <img src={item.itemImageSrc} alt={item?.alt || ''} width='100%'/>
                </TransformComponent>
            </TransformWrapper>
        </>
    //   <img src={item.itemImageSrc} alt={item.alt} style={{ width: '100%', display: 'block' }} />;
    );
};

const caption = (item:any) => {
    return (
        <React.Fragment>
            <div className="text-xl mb-2 font-bold">{item?.title || ''}</div>
            <p className="text-white" style={{whiteSpace: 'pre-line'}}>{item?.alt || ''}</p>
        </React.Fragment>
    );
}

const fsGalleriaProps:any = (show:boolean) => {
    if(show){
        return {
            display: 'flex',
            width: '100%', height: '100%',
            backgroundColor: 'black',
            zIndex: 1000,
            position: 'fixed',
            top: 0, left: 0,
        }
    }
    return {
        display: 'flex',
        width: '60%',
    }
}

export default function PopupEng(i:any, setVisibleDialog:any){
    if(i.count === 0) return;
    const index = GP?.data?.[i.product]?.eng?.scores?.[i.tactic]?.[i.tcode]?.[i.csf] || null;
    if(index === null){
      console.log('error: index is null');
      return false;
    }
  
    const techniqueName = GAT[13]['framework']['tactics'][i.tactic].techniques[i.tcode].name || 'unknown';
  
    const _threats = new Set(index.info.map((obj:any) => obj.threat));
    const _threatsObj = Array.from(_threats).map((threat:any) => ({'name' : threat}));

    
  
    function PopupComponent(props:any){
        const [look, setLook] = useState<any>([...index.info]);
        const [selectedThreats, setSelectedThreats] = useState<any>([..._threatsObj]);
        const galleria = useRef<any>(null);
        const [galIndex, setGalIndex] = useState<number>(0);
        const [fsGalleria, setFsGalleria] = useState<any>(false);
        const [imagesIndexArr, setImagesIndexArr] = useState<Array<any>>([]);
        const [images, setImages] = useState<any>([]);

        // const images = look.map((obj:any) => {
        //     return obj.images.map((img:any, counter:number) => ({
        //         'itemImageSrc' : engImageURL + img,
        //         'thumbnailImageSrc' : null,
        //         'alt' : `Criteria: ${obj.criteria}\nNote: ${obj.note || 'none'}`,
        //         'title' : `${i.product} against ${obj.threat} for technique ${i.tcode}. Image ${counter + 1} of ${obj.images.length}`,
        //     }));
        // }).flat();

        // const imagesIndexArr:Array<number> = look.map((obj:any) => {
        //     if(obj.images.length === 0) return;
        //     return obj.images.length;
        // });

        useEffect(() => {
            let activeIndex:any = null;
            let sum = 0;
            imagesIndexArr.forEach((imgCount, i) => { // [1,2,1]
                if(activeIndex !== null) return;
                sum += imgCount; // i=1, sum = 3
                
                if(sum >= (galIndex + 1)) activeIndex = i; // activeIndex = 1
            });
            $('.eng-criteria-text').map((i:any, el:any) => {
                if(i === activeIndex){
                    $(el).addClass('active');
                    const topOff = $('#criteria-list').offset().top;
                    const elOff = $(el).offset().top;
                    document.getElementsByClassName('eng-criteria-text active')[0].scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
                    // $('#criteria-list').animate({
                    //     scrollTop: (elOff - topOff) + 'px'
                    // }, 'fast');
                } else {
                    $(el).removeClass('active');
                }
            });
        },[galIndex]);

        useEffect(() => {
            const newLook = index.info.filter((obj:any) => selectedThreats.map((i:any) => i.name).includes(obj.threat));
            setGalIndex(0);
            setLook(newLook);
            setImagesIndexArr(newLook.map((obj:any) => {
                // if(obj.images.length === 0) return;
                return obj.images.length;
            }))
            setImages(newLook.map((obj:any) => {
                return obj.images.map((img:any, counter:number) => ({
                    'itemImageSrc' : engImageURL + img,
                    'thumbnailImageSrc' : null,
                    'alt' : `Criteria: ${obj.criteria}\nNote: ${obj.note || 'none'}`,
                    'title' : `${i.product} against ${obj.threat} for technique ${i.tcode}. Image ${counter + 1} of ${obj.images.length}`,
                }));
            }).flat())
            console.log(selectedThreats);
        },[selectedThreats]);

        // const captionProps = (showCaption || fsGalleria) ? {caption: caption} : {};
        const captionProps = {caption: caption};

        return(
            <Dialog header={`${techniqueName} [${i.tcode}] for [${i.product}]`}
                    maximizable
                    // maximized={true}
                    visible={true}
                    style={{ width: '95%', height: '95%', display: 'flex'}}
                    onHide={() => handleClick(null, setVisibleDialog, {'code' : i.tcode, 'tacticName' : i.tactic})}
                    >
                <div id='criteria-list' className="flex d-flex flex-column" style={{minWidth: '40%', overflowY: 'auto'}}>
                    <div className='flex d-flex flex-row' style={{alignItems: 'baseline', gap: '0.25rem'}}>
                        <p style={{fontWeight: 'bold'}}>Threats:</p>
                    <MultiSelect value={selectedThreats} onChange={(e) => setSelectedThreats(e.value)} options={_threatsObj} optionLabel="name" 
                        placeholder="Select Threats" maxSelectedLabels={1} className="w-full md:w-10rem" />
                    </div>
                    {Object.entries(look).map(([key, val]:any) => {
                        const imageCount = val.images.length;
                        // if(imageCount === 0) return;
                        const initActive = (parseInt(key) + galIndex) === 0;
                        return(
                        <React.Fragment key={`${i.tcode}-${key}-eng`}>
                            <div className={`eng-criteria-text ${(initActive) ? 'active' : ''}`} onClick={() => {
                                if(key === 0){
                                    setGalIndex(0);
                                } else {
                                    setGalIndex( sumArrayUpToIndex(imagesIndexArr, key - 1) )
                                }
                            }}
                            >
                                {<PiImageLight />} {`[${imageCount}] ${val.criteria}`}
                            </div>
                        </React.Fragment>
                        );
                    })}
                </div>
                <div style={{...fsGalleriaProps(fsGalleria)}}>
                    {/* <BsInfoCircleFill className="eng-info-icon" onMouseEnter={() => setShowCaption(true)} onMouseLeave={() => setShowCaption(false)}/> */}
                    <Galleria ref={galleria} value={images}
                            style={{ display: 'flex', width: '100%', position: 'relative'}}
                            showThumbnails={false}
                            activeIndex={galIndex}
                            onItemChange={(e) => setGalIndex(e.index)}
                            // showIndicators
                            showItemNavigators
                            // indicatorsPosition="left"
                            numVisible={9}
                            {...captionProps}
                            // indicator={indicatorTemplate}
                            header={
                                    <Button label="" severity="secondary" raised icon="pi pi-window-maximize" className='eng-fs-toggle' onClick={() => {
                                        setFsGalleria((prev:boolean) => !prev);
                                    }} />
                            }                            
                            item={ItemTemplate} />
                            
                </div>
            </Dialog>
        )
        }
    
        setVisibleDialog(<PopupComponent />)
          
  
  }