export function isEmptyObject(obj:any) {
    if(obj === undefined) return true;
    return Object.keys(obj).length === 0;
  }


  const _minColor:any = '#fff3f3';
  const _maxColor:any = '#ff0000';
  const _defaultColor:any = '#ffffff';
  const _noHeatThreshold:number = 2;
  
  function hexToRgb(hex:any) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }
  
  function componentToHex(c:number) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }
  
  function rgbToHex(r:number, g:number, b:number) {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
  }

export function buildThreatOverlay(heatObject:any){
    if(!Object.keys(heatObject).length){return;}
    const activeProject = '13';
    let overlayHeat:any = {};
    const minHeat = heatObject.min;
    const maxHeat = heatObject.max;
    let minColorValue:any = hexToRgb(_minColor)?.b;
    const baseColor:number|any = hexToRgb(_maxColor)?.r;
    let maxColorValue:any = hexToRgb(_maxColor)?.b;
    const diffColor = minColorValue - maxColorValue;
    const colorMultiplier = diffColor / (maxHeat - minHeat);
  
    Object.entries(heatObject.techniques).map((key:any, value:any)=>{
      const uniqueID = key[0];
      if(maxHeat < _noHeatThreshold){
        overlayHeat[uniqueID] = _defaultColor;
      }else{
        const heatScore = key[1].count;
        const heatColorInt = Math.round((maxHeat - heatScore) * colorMultiplier + maxColorValue);
        const heatColorHex = rgbToHex(baseColor, heatColorInt, heatColorInt);
        overlayHeat[uniqueID] = heatColorHex;
      }
    })
    return overlayHeat;
  };

export function uniqueIdParse(uniqueId:string){
  const regex = /(T[\.\d]+)(.+$)/gm;
  let m:any = regex.exec(uniqueId);
  return [m[1], m[2]];
}

export default function GFunctions(props:any){
    return(
        <></>
    )
}