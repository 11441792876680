import { GAT, GP } from "../GData/GData";
import { tacticTitle } from "./displayShared";
import { Tag } from 'primereact/tag';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SelectButton } from 'primereact/selectbutton';
import { Dialog } from 'primereact/dialog';
import { Tooltip } from 'primereact/tooltip';
import styles from './BuildAttackLayer.module.scss';
import './ThreatHandleClick.css';
import { BiLinkExternal, BiTargetLock, BiExpand, BiExpandHorizontal, BiMask } from 'react-icons/bi';
import { getThresholdText } from "./analysisFunctions";
import { MultiSelect } from 'primereact/multiselect';

import React, { Fragment, useState } from 'react';
import { count } from "console";
import { Button } from "primereact/button";
import PopupEng from "../Popups/PopupEng";
import { SiMediafire } from "react-icons/si";

const emptyScore = {
  'product' : '',
  'threatCount' : 1,
  'rc' : '',
  'i' : '',
  'p' : '',
  'd' : '',
  'r' : ''
}



export default function handleClick(event:any, setVisibleDialog:any, manual:any=null){
    // const AV = GetProjectAttackVersion();
    const AV = 13;
    let code:any = null;
    let tacticName:any = null;
    if(manual){
      code = manual.code;
      tacticName = manual.tacticName;
    }else{
      code = event.originalEvent.target.getAttribute('data-tech');
      tacticName = event.originalEvent.target.getAttribute('data-tac');
    }
  
    let desc = '';
    let name = '';
    let url = 'https://attack.mitre.org/'
    let tactics:any = null;
    let defendURL:any = null;
    let uniqueId:any = null;
    // const activeProject = GetActiveProject() || '';
    var scoredValues:any = {};
  
    // if(!dataOwner) dataOwner = [];
    // let COAs = GV?.[activeProject]?.COA || {};
    let isCOA = false;
    let threatCounter:any = null;
    
  
    if(code){ // This is a technique
      tactics = GAT[AV]['framework']['tactics'][tacticName].techniques[code].tactics;
      tactics = tactics.join(', ');
      desc = GAT[AV]['framework']['tactics'][tacticName].techniques[code].description;
      name = GAT[AV]['framework']['tactics'][tacticName].techniques[code].name;
      url = url + '/techniques/' + code.replace('.', '/');
      defendURL = "https://d3fend.mitre.org/offensive-technique/attack/" + code;
      uniqueId = code + tacticName;
      threatCounter = GAT?.[AV]?.['counter']?.['techniques']?.[uniqueId]?.count || null;
    }else{ // This is a tactic
      desc = GAT[AV]['framework']['tactics'][tacticName].description;
      name = tacticTitle(GAT[AV]['framework']['tactics'][tacticName].name);
      code = GAT[AV]['framework']['tactics'][tacticName].tacticId;
      url = url + '/tactics/' + code
    }

    const countFill = GAT?.['13']?.['scores']?.[uniqueId] || 'white'
  
    const header = (
      <div className="flex d-flex flex-row justify-content-between">
        <div>
          {`${name} [${code}]`}
        </div>
        <div style={{marginRight: '1rem'}}>
          {threatCounter && 
          <div className="flex d-flex flex-row align-items-center" style={{color: countFill, fill: countFill, gap: '0.5rem'}}>
            <BiMask />
            <span className='threat-counter'>{threatCounter}</span>
          </div>}
        </div>
        
      </div>
    );
  
    const capabilityScoreList = () => {
      if(!uniqueId){return <></>} // Clicked on a tactic, no capabilities are scored for tactics
      let scoreData:any = [];
      const selectedProducts = GP?.now?.selected || [];
      selectedProducts.forEach((product:string) => {
        const score = GP?.data?.[product]?.eng?.scores?.[tacticName]?.[code] || {};
        scoreData.push({...emptyScore, 
                       ...score,
                       'product' : product,
                       'threatCount' : 1});
      });
      const getCSFTemplate = (data:any, rows:any) => {
        const fdata = {
          product : data.product,
          csf : rows.field, 
          tcode : code,
          tactic : tacticName,
          count : data?.[rows.field]?.count || 0,
        }
        const text:string = getThresholdText(fdata.csf, fdata.count);
        
        let label = text.toUpperCase();
        if(fdata.count !== 0) label += ` [${fdata.count}]`;
        return(
          <div className={`table-pdr ${text} ${fdata.count && 'table-eng-popup'}`} onClick={() => PopupEng(fdata, setVisibleDialog)}>{label}</div>
        )
      };
      
  
      const getCapabilityTemplate = (capability:any) => {
        // if(!isCOA) return <>{capability.capability}</>
        // const actions = GV[activeProject].COA[dataOwner].actionTags.included[capability.capability];
        // const actions = {...GV[activeProject].COA[dataOwner].actionTags.included, ...GV[activeProject].COA[dataOwner].actionTags.excluded}
        // const action = actions[capability.capability]
        return(
          <>
            {/* {action && <Tag severity={action.severity} value={action.text} style={{marginRight: '0.25rem'}}/>} */}
            {capability.product}
          </>
        )
      }
  
      return(
        <div className="card dialog-table" style={{marginTop:'1rem'}}>
            <DataTable value={scoreData} sortField="capability" sortOrder={-1} size='small'>
                <Column field="product" header="Product Name" body={getCapabilityTemplate} sortable ></Column>
                <Column field="rc" header="Recover" alignHeader='center' body={getCSFTemplate} hidden={!GP?.now?.csf.includes('rc')} style={{width: 'fit-content'}}></Column>
                <Column field="i" header="Identify" alignHeader='center' body={getCSFTemplate} hidden={!GP?.now?.csf.includes('i')} style={{width: 'fit-content'}}></Column>
                <Column field="p" header="Protect" alignHeader='center' body={getCSFTemplate} hidden={!GP?.now?.csf.includes('p')} style={{width: 'fit-content'}}></Column>
                <Column field="d" header="Detect" alignHeader='center' body={getCSFTemplate} hidden={!GP?.now?.csf.includes('d')} style={{width: 'fit-content'}}></Column>
                <Column field="r" header="Respond" alignHeader='center' body={getCSFTemplate} hidden={!GP?.now?.csf.includes('r')} style={{width: 'fit-content'}}></Column>
            </DataTable>
        </div>
      )
    }
  
    setVisibleDialog(
      <Dialog header={header} 
              visible={true} 
              
              style={{ width: '65%' }} 
              onHide={() => setVisibleDialog(false)} 
              className={`${styles['dialog-technique-popup']} dialog-tech-pop`}
              dismissableMask={true}>
        <div className='d-flex flex-column'>
          <div className='d-flex flex-row align-items-center'>
            <strong>External MITRE Links:</strong>
              <a href={url} target='_blank'>
                <img src='/images/attack_logo.png' className='logo-dialog'/>
              </a>
              {defendURL &&
                <>
                  {/* <div className='vl-dialog'/> */}
                  <a href={defendURL} target='_blank'>
                    <img src='/images/defend_logo.png' className='logo-dialog'/>
                  </a>
                </>
              }
          </div>
          
          <div className='d-flex flex-row justify-content-between'>
            <div className='d-flex flex-column'>
              {tactics &&
                <p><strong>Tactic(s): </strong>{tactics}</p>}
            </div>
            {/* {tactics && 
              <div className='d-flex flex-column'>
                <p><strong>Weighted Likelihood of Mitigation: </strong>{resiliencyWeighted}</p>
                <p><strong>Thermal Protection: </strong>{resiliencyThermal}</p>
              </div>
            } */}
            
          </div>
          
          {capabilityScoreList()}
          <br/>
          <p><strong>Description: </strong>{desc}</p>
        </div>
      </Dialog>
    )
  }