

export const GAT:any = {};

export const GP:any = {'data' : {}, 'load' : {}};

export const engImageURL:string = 'https://attackevalscdnendpoint.azureedge.net/publicsiteimages/';

export default function GData(props:any){
    return(
        <></>
    )
}