import React from 'react';
import logo from './logo.svg';
import './App.css';
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.css';
import "primeicons/primeicons.css";
import ThreatApp from './components/ThreatApp/ThreatApp';
import NavbarApp from './components/NavbarApp/NavbarApp';

function App() {
  

  return (
    <div style={{height: '100vh'}} className='flex d-flex flex-column'>
        <NavbarApp />
        {/* <ThreatApp /> */}
        <BrowserRouter>
        <Routes>
          <Route path="/" element={<ThreatApp />} />
        </Routes>
        </BrowserRouter>
        
    </div>
  );
}

export default App;
