import { GP } from "../GData/GData";

// const mapColors:any = {
//     'low' : '#ff000040',
//     'med' : '#ffff0080',
//     'high' : '#00ff0080',
// }

const mapColors:any = {
    'low' : 'var(--color-csf-low)',
    'med' : 'var(--color-csf-med)',
    'high' : 'var(--color-csf-high)',
}

// --color-csf-high : #77CD88;
// --color-csf-med : #77cd88a1;
// --color-csf-low : #77cd8842;

const valToThreshold:any = {
    1 : 'low',
    2 : 'med',
    3 : 'high',
}

const threshold:any = {
    'rc' : {
        'low' : 1,
        'med' : 2,
        'high' : 3,
    },
    'i' : {
        'low' : 1,
        'med' : 2,
        'high' : 3,
    },
    'p' : {
        'low' : 1,
        'med' : 3,
        'high' : 5,
    },
    'd' : {
        'low' : 1,
        'med' : 6,
        'high' : 12,
    },
    'r' : {
        'low' : 1,
        'med' : 2,
        'high' : 3,
    },
}

export function getThresholdText(csf:string, count:number){
    const val = getThresholdValue(csf, count);
    if(val === 0) return('none');
    return valToThreshold[val];
}

function getThresholdValue(csf:string, count:number){
    if(count === 0) return 0;
    if(count <= threshold[csf].low) return 1;
    if(count <= threshold[csf].med) return 2;
    if(count <= threshold[csf].high) return 3;
    return 3;
}

function getThresholdValAgr(csfList:any){
    let max = 0;
    Object.keys(csfList).forEach((csf:string) => {
        const val = getThresholdValue(csf, csfList[csf]);
        if(val > max) max = val;
    });
    return max;
};

export function calculateOverlay(products:Array<string>, csf:Array<string>=['p','d']){
    let overlay:any = {};
    let d:any = {};
    products.forEach((product:string) => {
        if(!GP?.data?.[product]?.eng) return;
        let productData = GP.data[product].eng;
        Object.keys(productData.scores).forEach((tactic:string) => {
            Object.keys(productData.scores[tactic]).forEach((tcode:string) => {
                const uniqueId = tcode + tactic;
                let csfAgr:any = {};
                if(!d?.[uniqueId]) d[uniqueId] = 0;
                csf.forEach((csf:string) => {
                    const count = productData?.scores?.[tactic]?.[tcode]?.[csf]?.count || 0;
                    csfAgr[csf] = count;
                });
                const maxVal = d?.[uniqueId] || 0;
                const newVal = getThresholdValAgr(csfAgr);
                if(newVal > maxVal) d[uniqueId] = newVal;
            });
        });
    });
    Object.keys(d).forEach((uniqueId:any) => {
        const val = d[uniqueId];
        if(val === 0) return;
        overlay[uniqueId] = mapColors[valToThreshold[val]];
    });
    return overlay;
}