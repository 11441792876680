import { useEffect, useMemo, useRef, useState } from "react"
import { GetAttackFilesAll } from "../Loader/FileLoader"
import { GAT } from "../GData/GData";
import {INITIAL_VALUE, ReactSVGPanZoom, POSITION_NONE, TOOL_AUTO } from 'react-svg-pan-zoom';
import { generateDisplayAttackSurface } from "./displayAttack";
import handleClick from "./ThreatHandleClick";
import { ReactComponent as CSFWheel } from '../../locals/CSF_wheel.svg';
import $ from 'jquery';

import './ThreatMap.css';
import { csfInit } from "./ThreatApp";
import { buildThreatOverlay } from "../GData/GFunctions";

export default function ThreatMap(props:any) {

    const Viewer: any = useRef(null);
    const [visibleDialog, setVisibleDialog] = useState<any>(null);
    const [value, setValue] = useState(INITIAL_VALUE)
    const [isLoaded, setIsLoaded] = useState(false);
    const refWheel:any = useRef(null);
    const svgContent:any = useMemo(() => {
        if(!isLoaded) return([<></>, 0, 0]);
        let maxWidth:any = 0;
        let maxHeight:any = 0;
        let elements:any = [];
        const params = {
            AV : 13,
            // activeProject : activeProject,
            // filterSelected : filterSelected,
            showWeights : {'show' : true, 'type' : 'technique'},
            showInscopeValue : true,
            showSubsValue : true,
            // showGaps : showGaps,
            // pdrButton : pdrButton,
            // pdrSelection : props.pdrSelection,
            // titleLayer : props.titleLayer,
            colorOverlay: props.colorOverlay,
            // overlay: props.overlay,
            // selectedTechs : props.selectedTechs,
            // capabilitiesData : props.capabilitiesData,
          };
        elements = generateDisplayAttackSurface(params);
        return elements;
    }, [isLoaded, props.colorOverlay]);


  useEffect(() => {
    if(!Viewer?.current) return;
    Viewer.current.fitSelection(0, 0, svgContent[1], 0);
  }, [svgContent[1]]);

    useEffect(() => {
        GetAttackFilesAll().then(() => {
            GAT['13'].scores = buildThreatOverlay(GAT['13'].counter);
            setIsLoaded(true);
        });

        
    }, [])

    useEffect(() => {
        $('.csf-wheel-elm').on('click', (e:any) => {
            const csfId = e.target.dataset?.csf;
            if(csfId === 'toggle'){
                props.setCSF((prev:any) => {
                    if(prev.length > 0){
                        return [];
                    }else{
                        return [...csfInit];
                    }
                });
                return;
            }else{
                props.setCSF((prev:any) => {
                    if(prev.includes(csfId)){
                        return prev.filter((csf:string) => csf !== csfId);
                    }else{
                        return [...prev, csfId];
                    }
                });
            }
        });

        return(() => {
            $('.csf-wheel-elm').off('click');
        });
    }, [refWheel.current]);

    useEffect(() => {
        if(!props.csf) return;

        csfInit.forEach((csf:string) => {
            const elm = document.getElementById(`csf-w-${csf}`);
            if(!elm) return;
            if(props.csf.includes(csf)){
                elm.classList.add('active');
            }else{
                elm.classList.remove('active');
            }
        });
    }, [props.csf, refWheel.current]);



    if(!isLoaded) return(<></>);

    // useEffect(() => {
    //     let expandControl = props.attackToolbar?.[1] || 'expand-horizontal';
    //     switch(expandControl){
    //       case 'expand-all':
    //         Viewer.current.fitSelection(0, 0, props.maxWidth, props.maxHeight);
    //         break;
    //       case 'expand-horizontal':
    //         Viewer.current.fitSelection(0, 0, props.maxWidth, 0);
    //         break;
    //       default:
    //         Viewer.current.fitSelection(0, 0, props.maxWidth, 0);
    //     }
    //   }, [props.attackToolbar])
    

    return(
        <div className="container-threat-map" style={{position: 'relative'}}>
            <ReactSVGPanZoom  
                ref={Viewer}
                SVGBackground="#fff"
                background="#fff"
                width={props.dimensions[0]} height={props.dimensions[1]}
                tool={TOOL_AUTO}
                value={value} onChangeValue={setValue}
                miniatureProps={{'position' : POSITION_NONE}}
                toolbarProps={{'position' : POSITION_NONE}}
                scaleFactorOnWheel={1.15}
                onClick={(event:Event) => {
                // if(props.clickable === false) return;
                // else if(props.setManualSelectedTechs && props.inSelectMode) addSelectedTechs(event, props.setManualSelectedTechs, props.selectedTechs);
                    handleClick(event, setVisibleDialog);
                }}
                onChangeTool={(tool:any) => console.log(tool)}
                detectAutoPan={false}
                className='svg-main'
            >
                <svg>
                {/* {props?.gradient && BuildAttackLayer_Gradients} */}
                    {svgContent[0]}
                </svg>
            </ReactSVGPanZoom>
            {/* <img src='/images/CSF_wheel.svg' className='svg-csf-wheel'/> */}
            <CSFWheel ref={refWheel} className='svg-csf-wheel'/>
            {visibleDialog}
        </div>
    )
}