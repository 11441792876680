import { useRef, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import { Button } from 'primereact/button';
import './NavbarApp.css';
import { ReactComponent as TWlogo } from '../../locals/tw_logo.svg';
import { GP } from '../GData/GData';
import { Toast } from 'primereact/toast';
import TopTenDialog from '../TopTenDialog/TopTenDialog';

export default function NavbarApp(props:any) {
  const toast = useRef<any>(null);
  const [showTopTen, setShowTopTen] = useState<any>(false);
  return(
      <Navbar className="flex d-flex flex-horizontal justify-content-between nav-main">
        <Toast ref={toast} />
        <TopTenDialog isActive={showTopTen} setActive={setShowTopTen}/>
        <Navbar.Brand className='m-2 flex d-flex flex-row' style={{gap: '0.5rem', alignItems: 'center'}}>
          <TWlogo className='tw-logo'/>
          <div className='nav-text'>ThreatAware</div>
        </Navbar.Brand>
        <Navbar.Brand className=''>
          <Button label="Top 10" size='small' className="nav-bt-share" 
                  onClick={() => setShowTopTen(true)}/>
          <Button label="Share" size='small' icon="pi pi-copy" className="nav-bt-share"
                  onClick={() => {
                    const selected = GP?.now?.selected || [];
                    const urlParam = selected.length > 0 ? `?p=${selected.join(',')}` : '';
                    const url = `${window.location.origin}${window.location.pathname}${urlParam}`;
                    navigator.clipboard.writeText(url);
                    toast.current.show({ severity: 'success', summary: 'Copied to Clipboard', detail: url, life: 1500 });
                  }}/>
          <Button label="Help" size='small'
                  className="nav-bt-help"
                  onClick={() => window.open('https://github.com/tatedean/threat-aware', '_blank')}/>
        </Navbar.Brand>
    </Navbar>
  )
}