import useWindowSize from "../../modules/useWindowSize";
import ThreatCapabilities from "./ThreatCapabilities";
import ThreatMap from "./ThreatMap";
import { useEffect, useMemo, useRef, useState } from 'react';
import { calculateOverlay } from "./analysisFunctions";
import { GP } from "../GData/GData";
import { useLocation } from "react-router-dom";

export const csfInit = ['rc', 'i', 'p', 'd', 'r'];

export default function ThreatApp(props:any){
    const [widthWindow, heightWindow] = useWindowSize();
    const [dimensions, setDimensions] = useState<any>([]);
    const displayRef:any = useRef(null);
    const saveDimensions = useRef([0,0]);
    const [selectedProducts, setSelectedProducts] = useState<any>([]);
    const [csf, setCSF] = useState<any>(['p', 'd']);
    const location = useLocation();
    const overlay:any = useMemo(() => {
        GP.now = {
            'scores' : calculateOverlay(selectedProducts, csf),
            'csf' : csf,
            'selected' : selectedProducts,
        }
        return GP.now.scores;
    },[selectedProducts, csf]);
    const urlProducts = new URLSearchParams(location.search).get('p');
    
    useEffect(() => {
        if(!displayRef.current) {return;}
        let width = displayRef.current?.getBoundingClientRect().width - 3;
        let height = displayRef.current?.getBoundingClientRect().height - 5;
        saveDimensions.current = [width, height];
        setDimensions([saveDimensions.current[0], saveDimensions.current[1]]);
    
      },[widthWindow, heightWindow, displayRef.current])

    return(
        <div className="flex d-flex flex-horizontal" style={{height: '100%', overflow: "hidden"}}>
            <ThreatCapabilities update={setSelectedProducts} setCSF={setCSF} urlProducts={urlProducts}/>
            <div ref={displayRef} className='flex d-flex flex-row' style={{width: '100%', minWidth: '200px', overflow: 'hidden', border: '1px solid var(--nav-background)'}}>
                <ThreatMap 
                    showSubs={true}
                    showFilters={true}
                    showInscope={false}
                    csf={csf}
                    setCSF={setCSF}
                    // overlay={[]}
                    colorOverlay={overlay}
                    dimensions={dimensions}
                    // updateDimensions={updateDimensions}
                />
            </div>
        </div>
    )
}