import axios from "axios";
import { GAT, GP } from "../GData/GData";
import { isEmptyObject } from "../GData/GFunctions";

async function GetRootFile(filename:any, key:any){
    return axios(`/${filename}.json`).then(response => {
        GP[key] = response.data;
    });
}

export async function GetProductIndexFiles(){
    let promiseMe:any = [];
    promiseMe.push(GetRootFile('productIndex', 'data'));
    promiseMe.push(GetRootFile('productURL', 'url'));
    return await Promise.all(promiseMe);
}

async function GetAttackFile(AV:any, filename:any, key:any){
    return axios(`/attack/${AV}/${filename}.json`).then(response => {
        if (isEmptyObject(GAT?.[AV])) GAT[AV] = {};
        GAT[AV][key] = response.data;
    });
}

export async function GetAttackFilesAll(){
    const AV = 13;
    let promiseMe:any = [];
    promiseMe.push(GetAttackFile(AV, 'ordered', 'framework'))
    promiseMe.push(GetAttackFile(AV, 'threat-counter', 'counter'))
 
    return await Promise.all(promiseMe);
  }


export default function FileLoader(props:any){
    return(
        <></>
    )
}