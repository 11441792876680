import {useState, useEffect, useReducer} from 'react';

import './ThreatCapabilities.css';
import ProductCard from '../ProductApp/ProductCard';
import { InputText } from 'primereact/inputtext';
import { GP } from '../GData/GData';
import { GetProductIndexFiles } from '../Loader/FileLoader';
import { threatReducer, threatReducerInit } from './ThreatReducer';
import { Button } from 'primereact/button';
import { waitFor } from '@testing-library/react';
import { GetProductFiles } from '../Loader/ProductLoader';
import { stat } from 'fs';

function checkURLProducts(urlProducts:string){
    if(!urlProducts) return [];
    const productsArr = urlProducts.split(',');
    let validProducts:Array<string> = [];
    productsArr.forEach((p:any) => {
        p = p.trim().toLowerCase();
        if(GP.data?.[p]) validProducts.push(p);
    });
    if(validProducts.length === 0) return [];
    return validProducts;
}

export default function ThreatCapabilities(props:any){
    const [search, setSearch] = useState<any>('');
    const [isLoaded, setIsLoaded] = useState<any>(false);
    const [stateThreat, dispatchThreat] = useReducer(threatReducer, threatReducerInit);

    useEffect(() => {
        GetProductIndexFiles().then(() => {
            // setProducts(Object.keys(GP.data));
            dispatchThreat({
                type: 'setProducts',
                payload: Object.keys(GP.data),
            });
            const validProducts = checkURLProducts(props.urlProducts);
            if(validProducts.length > 0) dispatchThreat({type: 'setSelected', payload: validProducts});
            setIsLoaded(true);
        });
    }, [])

    useEffect(() => {
        if(!isLoaded) return;
        if(search === ''){
            dispatchThreat({
                type: 'setProducts',
                payload: Object.keys(GP.data),
            });
            return;
        }
        let filtered = Object.keys(GP.data).filter((product:any) => {
            return product.toLowerCase().includes(search.toLowerCase());
        })
        dispatchThreat({
            type: 'setProducts',
            payload: filtered,
        });
    },[search]);

    useEffect(() => {
        // if(stateThreat.selected.size === 0) return;
        GetProductFiles(Array.from(stateThreat.selected)).then(() => {
            props.update(Array.from(stateThreat.selected));
        });
    }, [stateThreat.selected]);

    if(!isLoaded) return(<></>);
    

    return(
        <div className="container-threat-capabilities">
            <div className="product-controls">
                <span className="p-input-icon-left" style={{width: '100%'}}>
                    <i className="pi pi-search" />
                    <InputText className="p-inputtext-sm"
                                placeholder="Search Products"
                                onChange={(e:any) => setSearch(e.target.value)}
                                style={{width: '100%'}}/>
                </span>
                <div className='flex d-flex flex-row'>
                    <Button size='small'
                            style={{padding: '0.25rem', width: '100%'}}
                            className='btn btn-secondary'
                            onClick={() => dispatchThreat({type: 'modSelected', mod: 'selectAll'})}
                            label='Select All'/>
                    <Button size='small'
                            className='btn btn-secondary'
                            style={{padding: '0.25rem', width: '100%'}}
                            onClick={() => dispatchThreat({type: 'modSelected', mod: 'clear'})}
                            label='Clear All' />
                </div>
            </div>
            <div className='product-cards'>
                {
                    stateThreat.products.map((product:any) => {
                        const isSelected = stateThreat.selected.has(product) ? 'active' : '';
                        return(
                            <ProductCard key={`card-${product}`}
                                         value={GP.data[product]}
                                         url={GP?.url[product]}
                                         active={isSelected}
                                         onClick={(e:any) => {
                                            const prod = e.target.dataset.prod;
                                            dispatchThreat({type: 'modSelected', mod: 'toggle', payload: prod})
                                        }}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}