import { GAT } from '../GData/GData';
import styles from './BuildAttackLayer.module.scss';

export function tacticTitle(text:string) {
    text = text.replaceAll('-',' ');
    return text.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
}

export function checkNameLength(text:string, isSub:boolean){
    if(isSub && 18 > text.length){return [text, 'text-normal']}
    if(isSub && 24 > text.length){return [text, 'text-shrink']}
    if(isSub){return [text.substring(0,21) + '...', 'text-shrink']}
    if(22 > text.length){return [text, 'text-normal']}
    if(33 > text.length){return [text, 'text-shrink']}
    return [text.substring(0,30) + '...', 'text-shrink']
  }

export function TacticElement(d:any){
    const showText = (d?.options?.showText === false) ? false : true;
    const setFill = (d?.options?.fill) ? d.options.fill : '#77777760';
    const techCount = Object.keys(GAT['13'].framework.tactics[d.tactic].techniques).length;

    return(
      <g key={d.tactic + '-group'}
        className={styles.threatGroup}>
        {/* <rect className={styles.tacticRect}
              id={d.tactic}
              x={d.xrect} y={d.yrect}
              width={d.width} height={d.height}
              data-tac={d.tactic} 
              fill={setFill}/> */}
        <text className={[styles['text-tactic'],styles['no-click']].join(' ')}
            x={d.xtext} y={d.ytext - 35}
            >{tacticTitle(d.tactic)}</text>
        <text className={[styles['text-tech-count'],styles['no-click']].join(' ')}
            x={d.xtext} y={d.ytext}
            >[{techCount}] techniques</text>
      </g>
    )
  }
  
export function TechniqueElement(d:any){
    const showText = (d?.options?.showText === false) ? false : true;
    const uniqueIdHTML = String(d.tcode + d.tactic).replaceAll('.','');
    return(
      <g key={uniqueIdHTML +'-group'} 
         className={styles.threatGroup}>
          <rect className={`${d.threatClass} ${d.selected}`}
                id={uniqueIdHTML}
                x={d.xrect} y={d.yrect}
                width={d.width} height={d.height}
                data-tac={d.tactic} data-tech={d.tcode}
                fill={d.fill}
                />
          <text className={[styles[d.textClass], styles['no-click']].join(' ')}
                x={d.xtext} y={d.ytext}
                >{d.text}</text>
      </g>
    )
  }
  
export function HeatElement(d:any){
    return(
      <g className={styles.threatGroup}>
        <rect className={styles['no-click']}
              width={d.width} height={d.height}
              x={d.xrect} y={d.yrect}
              fill={d.fill} />
        {/* <text className={styles['no-click']}
              x={d.xtext} y={d.ytext}>
              {d.score}
        </text> */}
      </g>
    )
  }