import { GP } from "../GData/GData";

// Initial state
export const threatReducerInit = {
    count: 0,
    products: [],
    selected: new Set(),
};
  
// Reducer function
export const threatReducer = (state:any, action:any) => {
    switch (action.type) {
        case 'setProducts':
            return {...state, products: action.payload};
        case 'setSelected':
            return {...state, selected: new Set(action.payload)};
        case 'modSelected':
            let newSelected = new Set(state.selected);
            if(!action?.mod) return state;
            if(action.mod === 'toggle'){
                if(newSelected.has(action.payload)) newSelected.delete(action.payload);
                else newSelected.add(action.payload);
            }
            else if(action.mod === 'clear') newSelected.clear();
            else if(action.mod === 'selectAll') newSelected = new Set(Object.keys(GP.data));
            return {...state, selected: newSelected};
        default:
            return state;
    }
};

export default function ThreatReducer(props:any){
    return(
        <></>
    )
}