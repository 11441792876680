import axios from "axios";
import { GP } from "../GData/GData";

async function GetProductFile(product:any){
    const filename = GP?.data?.[product]?.filename || null;
    if(!filename) return;
    return axios(`/products/${filename}`).then(response => {
        GP.data[product].eng = response.data;
    });
}

export async function GetProductFiles(products:string[]){
    if(!products) return;
    let promiseMe:any = [];
    for(let product of products){
        if(GP?.data?.[product]?.eng) continue;
        promiseMe.push(GetProductFile(product));
    }
    return await Promise.all(promiseMe);
}



export default function ProductLoader(props:any){
    return(
        <></>
    )
}